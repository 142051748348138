<!--
 * @Description: 编辑订单编辑信息
 * @Author: zhang zhen
 * @Date: 2023-02-06 14:30:31
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-06-17 16:23:04
 * @FilePath: /page-sass/src/views/PublishingRequirements/modules/editPartInfo.vue
-->
<template>
  <div class="PublishingRequirements">

    <div class="PublishingRequirements-content">
      <div class="completeInformation">
        <div class="completeInformation-form">
          <h2 class="completeInformation-form-title" style="margin-bottom: 0">
            <span class="title">散件(SKU)详细信息</span>
          </h2>
          <a-tabs v-model="formModel" disabled>
            <!-- <a-tab-pane :key="1" tab="模版填写" :disabled="!!this.purchaseId"></a-tab-pane> -->
            <a-tab-pane :key="2" tab="直接填写" :disabled="!!this.purchaseId"></a-tab-pane>
          </a-tabs>
          <template v-if="formModel == 1">
            <a-form-model ref="formD" class="basicInfo-form" :label-col="{
              span: 3,
            }" :wrapper-col="{
  span: 13,
}" :colon="false" :model="basicInfo3" style="margin-top: 10px">
              <a-row :gutter="16" class="basicInfo-form-item">
                <a-col :span="16">
                  <a-form-model-item label="下载模版" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" required>
                    <a-input v-show="false" v-model="basicInfo3.templateFile"></a-input>

                    <ExcelUploader @uploadFile="handleChangeModel" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="16">
                  <a-form-model-item label="上传附件" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" required>
                    <div class="uploadCover">
                      <div style="width: 334px">
                        <UploadDrag v-model="basicInfo3.planList" />
                      </div>
                      <div class="endTitle">可上传工艺图纸、样品照片、3D模型图等,支持多种文件格式</div>
                    </div>
                  </a-form-model-item>
                </a-col>
                <a-col :span="16">
                  <a-form-model-item label="其他需求" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
                    <a-textarea placeholder="请输入具体的质量要求，检测要求或制造工艺要求等" allow-clear v-model="basicInfo3.otherRequirements"
                      :autosize="{ minRows: 5, maxRows: 5 }" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </template>
          <template v-else>
            <div>
              <a-tabs v-model="activeIndex" class="max-tabs" type="card" :tabBarGutter="0" @change="handleChangeTab">
                <a-tab-pane v-for="(i, index) in formList" :tab="`需求单${index + 1}`" :key="index" :closable="true">
                  <a-form-model :ref="`settlementInfoForm${index}`" :model="{}" :colon="false" :label-col="labelCol"
                    :wrapper-col="wrapperCol" style="width: 960px">
                    <!-- 动态生成表单 -->
                    <a-row type="flex" justify="space-between" :gutter="40" class="completeInformation-form-newItem">
                      <a-col :span="item.span" v-for="item in i" :key="item.itemId">
                        <!-- 目前只提供了省市区是独立的 -->
                        <a-form-model-item :label="item.itemConfig.componentLabel"
                          :required="item.itemConfig.requiredFlag == '1'" :labelCol="{ span: item.labelColSpan }"
                          :wrapperCol="{ span: item.wrapperColSpan }">
                          <component :is="formLabelDict[item.itemConfig.componentType]" :dictCode="item.itemConfig.dictNo"
                            :placeholder="item.itemConfig.placeholder" :label="item.itemConfig.componentLabel"
                            :textLength="item.itemConfig.textLength" :contentType="item.itemConfig.contentType"
                            v-model="item.itemValue"></component>
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                    <!-- 动态生成表单结束 -->
                  </a-form-model>
                </a-tab-pane>
              </a-tabs>
            </div>
          </template>
          <a-row>
            <a-col :span="24" style="text-align: center; margin: 30px 0 20px">
              <a-button @click="handleCallBack"
                style="width: 120px; height: 38px; font-weight: 500; color: #262626">取消</a-button>
              <!-- :loading="loading" -->
              <a-button type="primary" @click="handleSubmitData" :disabled="formModel == 2 && !formList.length"
                style="width: 120px; height: 38px; font-weight: 500; margin-left: 8px">确认修改</a-button>
            </a-col>
          </a-row>
        </div>
        <ChoosePackagingMaterial ref="choosePackagingMaterialRef" />
      </div>
    </div>
  </div>
</template>

<script>
import ChoosePackagingMaterial from "@/components/plugins/ChoosePackagingMaterial.vue";
import { PDate } from '@/components/easyComponents'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import JMultiSelectTag from '@/components/plugins/JMultiSelectTag.vue'
import UploadDrag from '@/components/plugins/uploadDrag'
import { mapGetters, mapActions, mapState } from 'vuex'
import { postAction, getAction } from '@/api/manage'
import ExcelUploader from './uploadFIle.vue'
import textInput from './components/textInput.vue'
import radioDict from './components/radioDict.vue'
import selectMultiple from './components/selectMultiple.vue'
import selectSingle from './components/selectSingle.vue'
import datePicker from './components/datePicker.vue'
import dateTimePicker from './components/dateTimePicker.vue'
import provincePicker from './components/provincePicker.vue'
import UploadFile from './components/UploadFile.vue'
import sizeInput from './components/sizeInput.vue'
import splitLine from './components/splitLine.vue'
import TextArea from './components/textArea.vue'
export default {
  name: 'completeInformation',
  components: {
    ChoosePackagingMaterial,
    PDate,
    JDictSelect,
    UploadDrag,
    JMultiSelectTag,
    ExcelUploader,
    dateTimePicker,
    datePicker,
    selectSingle,
    selectMultiple,
    radioDict,
    textInput,
    provincePicker,
    UploadFile,
    sizeInput,
    splitLine,
    TextArea
  },
  props: {
    formType: {
      type: String,
      default: '',
    },
    purchaseId: {
      type: String,
    },
  },
  data() {
    return {
      tradeIdentityList: [
        {
          label: '下载模板填写',
          value: 1,
        },
        {
          label: '直接填写',
          value: 2,
        },
      ],
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      hadEditTab: false,
      loading: false,
      form: {},
      rules: {},
      formModel: 2,
      formLabelDict: {
        CHECKBOX: 'selectMultiple',
        TEXT: 'textInput',
        SELECT_SINGLE: 'selectSingle',
        SELECT_MULTIPLE: 'selectMultiple',
        DATE_TIME: 'dateTimePicker',
        DATE: 'datePicker',
        PROVINCE: 'provincePicker',
        RADIO: 'radioDict',
        FILE: 'UploadFile',
        WHD: 'sizeInput',
        SPLIT: 'splitLine',
        TEXTAREA: 'TextArea',
      },
      formSetting: {
        CHECKBOX: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16
        },
        TEXT: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16
        },
        SELECT_SINGLE: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16
        },
        SELECT_MULTIPLE: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16
        },
        DATE_TIME: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16
        },
        DATE: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16
        },
        PROVINCE: {
          span: 24,
          labelColSpan: 4,
          wrapperColSpan: 16
        },
        RADIO: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16
        },
        FILE: {
          span: 19,
          labelColSpan: 5,
          wrapperColSpan: 19
        },
        TEXTAREA: {
          span: 24,
          labelColSpan: 4,
          wrapperColSpan: 16
        },
        WHD: {
          span: 24,
          labelColSpan: 4,
          wrapperColSpan: 16
        },
        SPLIT: {
          span: 24,
          labelColSpan: 0,
          wrapperColSpan: 24
        }
      },
      basicObj: {
        boxShape: '',
        boxType: '',
        budget: '',
        circulationRoute: '',
        colorPrinting: false,
        contents: '',
        corrugatedLayers: '',
        corrugatedType: '',
        dynamicStackLayers: '',
        expandedSize: '',
        expectedDeliveryDate: '',
        expectedDeliveryRegion: '',
        forming: '',
        grade: '',
        grammage: '',
        internalProtection: '',
        loadingTool: '',
        logisticsMonitoring: '',
        manufacturingSize: '',
        maxWeight: '',
        otherRequirements: '',
        packagingQuantity: '',
        packagingStatus: '',
        printingColor: '',
        printingMethod: '',
        printingRequirements: '',
        productSize: '',
        productStructure: '',
        productWeight: '',
        purchaseId: '',
        purchaseItemId: '',
        quotationDeadline: '',
        scatteredName: '',
        specialProtection: '',
        staticStackLayers: '',
        transportationMode: '',
        uploadFile: '',
        usageCount: '',
        waveGlue: false,
        planList: [],
      },
      basicInfo3: {
        purchaseTitle: '',
        packagingType: [],
        participantCount: undefined,
      },
      rules3: {
        purchaseTitle: { required: true, message: '请输入需求名称' },
        packagingType: { required: true, message: '请选择包装类别' },
        productType: { required: true, message: '请选择产品分类' },
        participantCount: {
          required: true,
          message: '请输入参与供应商的数量',
        },
        description: { required: true, message: '请输入产品的使用场景及产品的规格' },
        paymentMethod: { required: true, message: '请选择付款方式' },
        purchaseType: { required: true, message: '请选择需求类型' },
        priceType: { required: true, message: '请选择价格类型' },
        printProcess: { required: true, message: '请选择表面覆膜' },
      },
      activeIndex: 0,
      formList: [],
      packageList: [],
      keyRow: {
        product: 0,
        package: 1,
        plan: 2,
      },
      getPopupContainer: (node) => node.parentNode,
      expectedDeliveryProvince: [],
      expectedDeliveryCityList: [],
      expectedDeliveryDistrictList: [],
      baseFormItemList: [],
    }
  },
  computed: {
    ...mapState({
      packagingMaterial: state => state.packagingMaterial
    })
  },
  watch: {
    packagingMaterial: {
      handler(newVal) {
        console.log(newVal, 1111)
        if (newVal) {
          this.handleCreateNewFiled();
        }
      },
      immediate: true
    }
  },
  created() {
    this.activeIndex = 0
    this.hadEditTab = false
    this.handleInitDict(true)
  },
  mounted() {
    this.handleInitForm()
  },
  methods: {
    ...mapActions(['asyncChangeStatus', 'asyncChangePackagingMaterial']),
    handleChangeImage(e, index) {
      if (e[0]) {
        this.formList[index].file = e[0].srcFileName
      } else {
        this.formList[index].file = null
      }
      this.$forceUpdate()
      console.log(this.$refs[`settlementInfoForm${this.activeIndex}`][0])
      this.$refs[`settlementInfoForm${this.activeIndex}`][0].clearValidate('file')
      console.log(e, '2222,', index, this.$refs[`settlementInfoForm${this.activeIndex}`])
    },
    ...mapGetters(['setUserInfo']),
    checkNegative(e, form, index, key) {

      var license_num = e.target.value
      license_num = license_num.replace(/[^\d]/g, '') // 清除“数字”和“.”以外的字符
      if (license_num.indexOf('.') < 0 && license_num != '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        form[index][key] = parseInt(license_num)
      }
      form[index][key] = license_num
      this.$forceUpdate()
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this.expectedDeliveryProvince = data
        }
      })
    },
    changeInfo(e) {
      this.formList[this.activeIndex].expectedDeliveryProvince = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleChangeTab() {
      this.expectedDeliveryCityList = []
      this.expectedDeliveryDistrictList = []
      this.formList[this.activeIndex].expectedDeliveryProvince && this.handleChangeRootId(true)
      this.formList[this.activeIndex].expectedDeliveryDistrict && this.handleLoadCity('deliveryDistrict', true)
    },
    handleChangeRootId(flag = false) {
      this.expectedDeliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.formList[this.activeIndex].expectedDeliveryCity = undefined
        this.formList[this.activeIndex].expectedDeliveryDistrict = undefined
      }
      this.handleLoadCity('expectedDeliveryCity', flag)
      this.formList[this.activeIndex].expectedDeliveryDistrict && this.handleLoadCity('expectedDeliveryDistrict', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'expectedDeliveryCity') {
        this.expectedDeliveryCityList = []
        this.expectedDeliveryDistrictList = []
        !flag && (this.formList[this.activeIndex].expectedDeliveryCity = undefined)
        parentId = this.formList[this.activeIndex].expectedDeliveryProvince
      }
      if (key == 'expectedDeliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.formList[this.activeIndex].expectedDeliveryDistrict = undefined)
        parentId = this.formList[this.activeIndex].expectedDeliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    handleInitDict(needInit = false) {
      getAction('/material/category/queryByType', { categoryType: '2' }).then((res) => {
        const { data, success } = res
        success && data && (this.packageList = data)
      })
      // this.handleCreateNewFiled() // 表单字段会显示
    },
    // 生成表单字段
    handleCreateNewFiled() {
      // 发布的时候选择需求的包装材料
      // const { packagingMaterial } = this.$route.query;
      // if (packagingMaterial) {
      getAction('/v2/purchases/queryEffectiveVersion', {
        wrapType: this.packagingMaterial
      }).then(res => {
        const { success, data, message } = res;
        const { id, wrapType, items } = data;
        this.asyncChangePackagingMaterial(null)
        if (success) {
          this.baseFormItemList = items.map(i => {
            return {
              itemConfig: i,
              itemId: i.id,
              itemValue: "",
              itemValueDictText: "",
              purchaseId: "",
              versionId: id,
              versionItemId: i.versionItemId || '',
              wrapType,
              ...this.formSetting[i.componentType]
            }
          })
          this.handleAddRow()
        } else {
          this.$message.warning(message)
        }
      })
      // }
    },
    // 检验时间
    checkDateTime(rule, value, callback) {
      const { expectedDeliveryDate } = this.formList[this.activeIndex]
      if (!value) {
        callback(new Error('报价截止日期不能为空!'))
      } else if (expectedDeliveryDate && value >= expectedDeliveryDate) {
        callback(new Error('报价截止日期不能晚于期望收货日期!'))
      } else {
        callback()
      }
    },
    handleChangeModel({ fileUrl, fileId }) {
      this.basicInfo3.templateFile = fileUrl
      this.$forceUpdate()
    },
    checkTemplate(rule, value, callback) {
      const { templateFile } = this.basicInfo3
      if (!templateFile) {
        callback(new Error('请上传下载模板'))
      } else {
        callback()
      }
    },
    checkFile(rule, value, callback) {
      if (!this.formList[this.activeIndex]['file']) {
        callback(new Error('请上传设计方案!'))
      } else {
        callback()
      }
    },
    checkExpectedDeliveryDate(rule, value, callback) {
      // const { quotationDeadline } = this.formList[this.activeIndex]
      if (!value) {
        callback(new Error('期望收货日期不能为空!'))
      } else if (this.basicInfo3.quotationDeadline && value <= this.basicInfo3.quotationDeadline) {
        callback(new Error('期望收货日期不能早于基础信息里面的止日期!'))
      } else if (!this.basicInfo3.quotationDeadline) {
        this.$refs.formC.validateField('quotationDeadline')
        this.formList[this.activeIndex]['expectedDeliveryDate'] = ''
      } else {
        callback()
      }
    },
    handleAddRow() {
      this.formList.push([...this.baseFormItemList])
    },
    /* 删除tab */
    handleChangeFormList(index) {
      if (this.formList.length == 1) return this.$message.warning('当前需求单不能删除，至少保留一个需求单')
      this.formList.splice(index, 1) // 删除
      setTimeout((_) => (this.activeIndex = 0), 300)
    },
    /* 回显示表单数据 */
    handleInitForm() {
      if (!this.$route.query.orderNo) return // 拦截
      getAction(`/order/order/queryOrderItem`, {
        orderNo: this.$route.query.orderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { forms, baseInfo } = data
          if (baseInfo) {
            this.basicInfo3 = Object.assign({}, baseInfo)
            this.formModel = baseInfo.templateFile ? 1 : 2

          }
          if (forms && forms.length) {
            this.formList = forms.map((i) => {
              const { formItemDtoList } = i
              return formItemDtoList.map(item => {
                const { componentType } = item.itemConfig
                return {
                  ...item,
                  ...this.formSetting[componentType]
                }
              })
            })
            this.handleChangeTab()
          }
        } else {
          this.$message.warning({
            content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${message}`}</span>,
            icon: (h) => <img src="./noPass.png" width="21" height="21" />,
          })
        }
      })
    },
    /* 存放参数 */
    handleUpLoadInput(value) {
      console.log(value)
      let targetItem = this.packageList.find((i) => i.categoryId == value)
      if (targetItem) {
        const { categoryName } = targetItem
        this.basicInfo3['packagingTypeName'] = categoryName
        this.$forceUpdate()
      }
    },
    handleSubmitData() {
      let formArr = []
      if (this.formModel != 1) {
        this.formList.forEach((item, index) => {
          formArr.push('settlementInfoForm' + index)
        })
      } else {
        if (!this.basicInfo3.templateFile) {
          return this.$message.warning('下载模版不能为空')
        }
        if (!this.basicInfo3.planList) {
          return this.$message.warning('上传附件不能为空')
        }
      }
      const that = this
      this.loading = true
      let formData = [],
        baseInfo = { ...this.basicInfo3 }
      if (this.formModel != 1) {
        formData = this.formList.map((i) => {
          let formItemDtoList = i.map(item => {
            let items = { ...item }
            // items.itemConfig && delete items.itemConfig
            return items
          })
          return {
            formItemDtoList
          }
        })
        // purchaseId
        // formData.map((i) => (i.purchaseId = this.purchaseId))
      } else {
        if (!this.basicInfo3.templateFile) {
          return this.$message.warning('模板不能为空')
        }
        if (!this.basicInfo3.planList) {
          return this.$message.warning('附件不能为空')
        }
        baseInfo.uploadFile = this.basicInfo3.planList.map((item) => item.url || '').toString()
        baseInfo.planList && delete baseInfo.planList
      }
      postAction('/order/order/updateOrderItem', {
        forms: formData,
        orderNo: this.$route.query.orderNo
      }).then((res) => {
        const { success, message, data } = res
        this.loading = false
        if (success) {
          this.$message.success(message)
          this.handleCallBack();
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleCallBack() {
      this.$router.go(-1)
    },
    handleChangeMainTab(e) {
      if (e == 2) {
        this.asyncChangeStatus(true);
        this.formList = []
        this.$nextTick(_ => {
          this.$refs.choosePackagingMaterialRef.handleInitData(this.basicInfo3.purchaseType)
        })
      }
    },
    /* 编辑 */
    onEdit(targetKey, action) {
      if (action == 'add') {
        this.asyncChangeStatus(true);
        // this.handleAddRow()
        this.$nextTick(_ => {
          this.$refs.choosePackagingMaterialRef.handleInitData(this.basicInfo3.purchaseType)
        })
      } else {
        // choosePackagingMaterialRef
        this.handleChangeFormList(targetKey)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.completeInformation {
  &-form {
    &-title {
      font-size: 15px;
      color: #605f5f;
      margin-bottom: 30px;
      height: 38px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EFEFEF;

      &:before {
        background: #ff6e2d;
        display: inline-block;
        content: '';
        width: 5px;
        height: 16px;
        margin-right: 8px;
      }

      .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000;
        margin-right: 10px;
      }

      .subTitle {
        font-weight: 400;
        font-size: 14px;
        color: #8d8e99;

        ::v-deep .ant-radio-wrapper {
          color: #262626;
        }
      }
    }

    &-item {
      padding: 0 220px 0 4px;
    }

    &-newItem {
      padding-right: 60px;
    }
  }

  .chooseBtn {
    width: 184px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    position: relative;

    &.active {
      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 16px;
        height: 6px;
        top: 4px;
        right: 6px;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        z-index: 12;
        transform: rotate(330deg);
      }

      &::after {
        // background: #009688;
        display: block;
        content: '';
        position: absolute;
        top: -1px;
        right: 0;
        border-top: 20px solid #009688;
        border-right: 20px solid #009688;
        border-bottom: 20px solid transparent;
        border-left: 20px solid transparent;
      }
    }
  }

  .endTitle {
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }

  ::v-deep .ant-tabs-left-content {
    padding-left: 30px;
    border-left: none;
  }

  .option-area {
    width: 139px;
    height: 38px;
    background: #fff5ee;
    border: 1px solid #ff6e2d;
    border-radius: 4px;
    padding: 8px 26px 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 20px;
    top: 58px;
    z-index: 99;

    &-item {
      color: #ff6e2d;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
    }

    .ant-divider-vertical {
      height: 14px;
      background-color: #ff6e2d;
      margin: 2px 10px 0;
    }
  }

  ::v-deep.ant-tabs {
    color: #131212;
  }

  .tabName {
    display: flex;
    align-items: center;

    .icons {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #c4c4c4;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }

  .speLabel {
    // ::v-deep .ant-checkbox-inner {
    //   border-radius: 50%;
    // }
    // ::v-deep .ant-checkbox {
    //   border-radius: 50%;
    // }
    // ::v-deep .ant-checkbox-inner::after {
    //   left: 20%;
    // }
  }

  .NumberInfo {
    color: rgba(0, 0, 0, 0.45);
  }

  .hiddenTitle {
    ::v-deep .ant-form-item-label {
      visibility: hidden;
    }
  }

  .uploadCover {
    width: 560px;
    // min-height: 186px;
    padding: 32px 0 20px;
    background: #f9f9f9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ::v-deep.ant-form-item-label>label {
    color: #000;
  }

  .point {
    color: #000;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .longLabel {
    overflow: visible;

    ::v-deep .ant-form-item-label-left {
      overflow: visible;
    }

    ::v-deep.ant-form-item-no-colon {
      margin-left: -20px;
    }
  }
}

// .has-error {
//   .uploadCover, .uploadView {
//     border: 1px solid #EE4261;
//   }
// }

:deep .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding-right: 22px;
  padding-top: 10px !important;
}

.max-tabs {
  margin-top: 10px;

  ::v-deep .ant-tabs-extra-content {
    width: 37px;
    background: #F2F3F5;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-tabs-new-tab {
      background: transparent;
      border: none;
    }
  }

  ::v-deep .ant-tabs-tab {
    border-radius: 0 !important;

    &:not(:nth-child(1)) {
      border-left: none !important;
    }
  }
}

.PublishingRequirements {
  padding: 30px 0 64px;
  .flexLayout(@direction: column; @justifyContent: flex-start; );
  background-color: rgb(246, 247, 249);

  &-content {
    // max-width: 1184px;
    // width: calc(100vw - 280px);
    width: 1288px;
    max-width: 1288px;
    padding: 15px;
    box-sizing: border-box;
    padding: 24px 24px 64px 24px;
    background: #ffffff;
    border-radius: 8px;
  }

}
</style>
